import { zodResolver } from '@hookform/resolvers/zod'
import { Button, ErrorNotification } from '@mr-yum/frontend-ui'
import React, { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation, useQuery } from 'urql'
import { z } from 'zod'

import { AddressField } from '@/components/HookForm/AddressField'
import { InputField } from '@/components/HookForm/InputField'
import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import {
  AddOrUpdateLocationOnCartDocument,
  CurrentLocationPartsFragment,
  OrderingType,
  VenueAddressValidationDocument,
} from '@/gql/graphql'

import { AddressPostcodes } from './AddressPostcodes'

interface Props {
  location?: CurrentLocationPartsFragment | null
  onClose?: () => void
}

export const AddressForm = ({ location, onClose }: Props) => {
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const intl = useIntl()

  const [{ data }] = useQuery({
    query: VenueAddressValidationDocument,
    variables: { venueSlug },
    requestPolicy: 'cache-and-network',
  })

  const schema = z.object({
    unitNumber: z.string(),
    googlePlaceId: z.string(),
    streetAddress: z.array(z.string()).refine((arr) => arr.length > 0, {
      message: intl.formatMessage({
        defaultMessage: 'Select your address from the dropdown',
        id: 'hvdOjf',
      }),
    }),
  })

  type FieldValues = z.input<typeof schema>

  const [{ error }, addOrUpdateLocationOnCart] = useMutation(
    AddOrUpdateLocationOnCartDocument,
  )

  const submitHandler = useCallback(
    async ({ googlePlaceId, unitNumber, streetAddress }: FieldValues) => {
      const { error } = await addOrUpdateLocationOnCart({
        input: {
          venueSlug,
          orderingType,
          unitNumber,
          googlePlaceId,
          streetAddress: streetAddress[0],
        },
      })

      if (!error) {
        onClose?.()
        return
      }
    },
    [venueSlug, orderingType, addOrUpdateLocationOnCart, onClose],
  )

  let lat = -25
  let lng = 134
  const venue = data?.guestVenue
  if (venue?.location?.latitude && venue?.location?.latitude) {
    lat = venue.location.latitude
    lng = venue.location.latitude
  } else if (venue?.countryCode === 'ZA') {
    lat = -29
    lng = 25
  }

  const deliverySettings = venue?.orderingTypes?.find(
    (setting) => setting.orderingType === OrderingType.Delivery,
  )

  const postcodes = deliverySettings?.postcodes || []

  const form = useForm<FieldValues>({
    mode: 'all',
    defaultValues: {
      unitNumber: location?.unitNumber ?? '',
      googlePlaceId: location?.googlePlaceId ?? '',
      streetAddress: location?.formattedAddress
        ? [location.formattedAddress]
        : [],
    },
    resolver: zodResolver(schema),
  })

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)}>
        {error && (
          <div className="mb-4">
            <ErrorNotification fullWidth error={error} />
          </div>
        )}
        <AddressField
          autoFocus
          name="streetAddress"
          lat={lat}
          lng={lng}
          radius={50_000}
          label={intl.formatMessage({
            defaultMessage: 'Delivery address',
            id: 'v3tQCl',
          })}
          placeholder={intl.formatMessage({
            defaultMessage: 'Search for address',
            id: 'D7lLal',
          })}
        />
        <InputField
          clearable
          name="unitNumber"
          label={intl.formatMessage({
            defaultMessage: 'Apartment number (optional)',
            id: 'AMm4WK',
          })}
          placeholder={intl.formatMessage({
            defaultMessage: 'Apartment number',
            id: 'ywUni4',
          })}
        />
        {orderingType === OrderingType.Delivery && postcodes.length > 0 && (
          <AddressPostcodes postcodes={postcodes} />
        )}
        <Button
          size="lg"
          type="submit"
          className="w-full"
          isLoading={form.formState.isSubmitting}
          aria-label={intl.formatMessage({
            defaultMessage: 'Address confirm',
            id: 'H/tG0k',
          })}
        >
          <FormattedMessage id="N2IrpM" defaultMessage="Confirm" />
        </Button>
      </form>
    </FormProvider>
  )
}
