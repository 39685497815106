import { getHumanShortDateFormat } from '@mr-yum/frontend-core/dist/support/dates'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import format from 'date-fns/format'
import isToday from 'date-fns/isToday'
import parseISO from 'date-fns/parseISO'
import React from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

import { OrderingType } from '@/gql/graphql'

interface OrderingWindowProps {
  orderingType: OrderingType
  startDate?: string | null
  endDate?: string | null
  className?: string
}

export const OrderingWindow = ({
  orderingType,
  startDate,
  endDate,
  className,
}: OrderingWindowProps) => {
  if (!startDate) return null

  const isPickup = orderingType === OrderingType.PickUp
  const isCounterPickup = orderingType === OrderingType.Counter

  const start = parseISO(startDate)
  const end = endDate && parseISO(endDate)

  const daysAway = differenceInCalendarDays(start, new Date())
  const day = isToday(start) ? (
    <FormattedMessage defaultMessage="Today" id="zWgbGg" />
  ) : (
    <FormattedDate value={start} weekday="long" />
  )
  const date = format(start, getHumanShortDateFormat())

  const time =
    isPickup || isCounterPickup ? (
      <FormattedMessage
        defaultMessage="at {start}"
        id="KN+drV"
        values={{ start: format(start, 'p') }}
      />
    ) : (
      <FormattedMessage
        defaultMessage="between {start}{end}"
        id="Ud8NcX"
        values={{
          start: format(start, 'p'),
          end: end ? `-${format(end, 'p')}` : '',
        }}
      />
    )

  return (
    <p className={className}>
      {daysAway < 7 && day}
      {daysAway >= 7 && date} {time}
    </p>
  )
}
