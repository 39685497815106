import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  Button,
  createListCollection,
  ErrorFilledIcon,
  FieldMessage,
  FormControl,
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from '@mr-yum/frontend-ui'
import toString from 'lodash/toString'
import Router, { useRouter } from 'next/router'
import React, { useCallback, useMemo } from 'react'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from 'urql'

import { InputField } from '@/components/HookForm/InputField'
import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import {
  AddOrUpdateTableNumberOnCartDocument,
  TableNumberValidation,
  VenueTableNumberQuery,
} from '@/gql/graphql'
import { useLogger } from '@/hooks/useLogger'
import { getCleanPath } from '@/lib/utils'

import {
  getTableNumberRequirement,
  getTableNumberSettings,
  getValidationSchema,
} from './TableNumberUtils'
import { FormValues, TableNumberProps } from './types'

export const TableNumberForm = ({
  venue,
  onClose = () => false,
  number,
  area,
  portalRef,
}: TableNumberProps & {
  venue: NonNullable<VenueTableNumberQuery['guestVenue']>
}) => {
  const [, addOrUpdateTableNumberOnCart] = useMutation(
    AddOrUpdateTableNumberOnCartDocument,
  )

  const intl = useIntl()
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const { pathname, asPath } = useRouter()

  const onCartPage = pathname.split('/').includes('cart')

  const { logEvent } = useLogger()
  const settings = getTableNumberSettings(intl, venue)
  const hasTableAreas = settings.tableAreas.length > 0

  const defaultValues = useMemo<FormValues>(() => {
    return {
      tableArea: area ? [area] : [],
      tableNumber: number ?? '',
    }
  }, [number, area])

  const form = useForm<FormValues>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(
      getValidationSchema(
        {
          tableText: settings.tableText,
          tableNumberValidation: settings.tableNumberValidation,
          minTableNumber: settings.minTableNumber,
          maxTableNumber: settings.maxTableNumber,
          hasTableAreas,
          tableNumberRequirement: getTableNumberRequirement(venue),
        },
        intl,
      ),
    ),
  })

  const {
    setError,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = form

  const submitHandler = useCallback<SubmitHandler<FormValues>>(
    async ({ tableArea, tableNumber }) => {
      if (onCartPage) {
        logEvent('Clicked confirm on update table number modal in cart')
      }

      const { error } = await addOrUpdateTableNumberOnCart({
        input: {
          venueSlug,
          orderingType,
          tableArea: tableArea?.[0] || undefined,
          tableNumber: toString(tableNumber),
        },
      })

      if (error) {
        return setError('root.serverError', {
          type: '400',
          message: error.graphQLErrors[0]?.message,
        })
      }

      void Router.replace(
        getCleanPath(pathname),
        asPath ? getCleanPath(asPath) : undefined,
        { shallow: true },
      )

      return onClose()
    },
    [
      onCartPage,
      addOrUpdateTableNumberOnCart,
      venueSlug,
      orderingType,
      pathname,
      asPath,
      onClose,
      logEvent,
      setError,
    ],
  )

  const tableAreaOptions = createListCollection({
    items: (settings.tableAreas || []).map((option: string) => ({
      label: option,
      id: option,
    })),
    itemToValue: (item) => item.id,
  })

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(submitHandler)}>
        {errors.root && (
          <Alert
            className="mb-4"
            variant="critical-subtle"
            icon={<ErrorFilledIcon />}
            fullWidth
          >
            {errors.root.serverError.message}
          </Alert>
        )}

        {hasTableAreas && (
          <Controller
            name="tableArea"
            render={({ field }) => (
              <FormControl
                label={
                  settings.tableAreaText ||
                  intl.formatMessage({
                    defaultMessage: 'Table area',
                    id: '6nnIPg',
                  })
                }
                feedback={
                  !!errors.tableArea && (
                    <FieldMessage type="critical">
                      {errors.tableArea.message}
                    </FieldMessage>
                  )
                }
                htmlFor="tableArea"
              >
                <SelectRoot
                  variant="subtle"
                  name={field.name}
                  value={field.value}
                  collection={tableAreaOptions}
                  onValueChange={({ value }) => {
                    field.onChange(value)
                  }}
                  onInteractOutside={field.onBlur}
                >
                  <SelectTrigger>
                    <SelectValueText placeholder="Select..." />
                  </SelectTrigger>
                  <SelectContent portalRef={portalRef}>
                    {tableAreaOptions.items.map((option) => (
                      <SelectItem key={option.id} item={option}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </SelectRoot>
              </FormControl>
            )}
          />
        )}

        <Controller
          name="tableNumber"
          render={({ field }) => (
            <InputField
              label={
                settings.tableText ||
                intl.formatMessage({
                  defaultMessage: 'Table number',
                  id: 'SCjZeO',
                })
              }
              name="tableNumber"
              placeholder={
                settings.tableText ||
                intl.formatMessage({
                  defaultMessage: 'Table number',
                  id: 'SCjZeO',
                })
              }
              type={
                settings.tableNumberValidation === TableNumberValidation.Number
                  ? 'number'
                  : 'text'
              }
              inputMode={
                settings.tableNumberValidation === TableNumberValidation.Number
                  ? 'decimal'
                  : undefined
              }
              pattern={
                settings.tableNumberValidation === TableNumberValidation.Number
                  ? '[0-9]*'
                  : undefined
              }
              clearable={true}
              className={{ input: 'no-number-controls' }}
              onClear={() => field.onChange('')}
              autoFocus={!hasTableAreas}
            />
          )}
        />

        <Button
          type="submit"
          fullWidth
          size="lg"
          data-testid="table-number-submit"
          isLoading={isSubmitting}
          aria-label={intl.formatMessage({
            defaultMessage: 'Table number confirm',
            id: 'sljl18',
          })}
        >
          <FormattedMessage id="N2IrpM" defaultMessage="Confirm" />
        </Button>
      </form>
    </FormProvider>
  )
}
