import { CenterSpinner } from '@mr-yum/frontend-ui'
import React from 'react'
import { useQuery } from 'urql'

import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import { VenueTableNumberDocument } from '@/gql/graphql'

import { TableNumberForm } from './TableNumberForm'
import { TableNumberProps } from './types'

export const TableNumberController = (props: TableNumberProps) => {
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const [{ data, stale }] = useQuery({
    query: VenueTableNumberDocument,
    variables: {
      venueSlug,
      orderingType,
    },
  })
  const venue = data?.guestVenue

  return stale || !venue ? (
    <CenterSpinner />
  ) : (
    <TableNumberForm venue={venue} {...props} />
  )
}
