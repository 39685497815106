import { parseTimeAsDate } from '@mr-yum/frontend-core/dist/support/dates'
import { Button } from '@mr-yum/frontend-ui'
import { isToday } from 'date-fns'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { AvailableSlot } from '@/gql/graphql'

import { Window } from './OrderingWindowsForm'

interface Props {
  date: Date
  window: AvailableSlot
  hasAsap: boolean
  loading: boolean
  selected: Window | null | undefined
  startDate?: string | null
  setSelected: (item: Window | null) => void
}

export const AsapButton = ({
  date,
  window,
  hasAsap,
  selected,
  startDate,
  setSelected,
  loading,
}: Props) => {
  let isActive = false

  if (selected === null) {
    isActive = true
  } else if (selected === undefined && startDate === null) {
    isActive = true
  }

  const today = isToday(parseTimeAsDate(date, window.startTime))

  const buttonIsAsap = hasAsap && today
  const isSelected = hasAsap && isActive

  if (!buttonIsAsap) {
    return null
  }

  return (
    <div data-testid="asap-ordering-window">
      <Button
        type="button"
        variant={isSelected ? 'primary-alternative' : 'outline'}
        aria-selected={isSelected}
        aria-label="Asap button"
        onClick={() => {
          setSelected(null)
        }}
        isLoading={selected === null && loading}
        className="h-full"
        fullWidth
      >
        <div className="flex min-h-[36px] flex-col items-center justify-center">
          <div className="mb-1 text-current my-label-sm">
            <div className="inline whitespace-nowrap">
              <FormattedMessage defaultMessage="ASAP" id="K5T4eC" />
            </div>
          </div>
        </div>
      </Button>
    </div>
  )
}
