import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { Button, Spinner } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import React, { HTMLAttributes, PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'

import { OrderingTypeSlug, routes } from '@/lib/routes'

export const CartLayoutBody = ({
  children,
  className,
}: HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn(className, 'px-4 sm:px-5')}>{children}</div>
}

interface Props {
  sticky?: boolean
  noSpacer?: boolean
  shadow?: boolean
}

export const CartLayoutFooter = ({
  children,
  sticky,
  noSpacer = false,
  shadow = true,
  ...props
}: HTMLAttributes<HTMLDivElement> & Props) => {
  return (
    <>
      {!noSpacer && <div className="flex-1" />}
      <div
        className={cn(
          'bottom-0 left-0 z-[9] mt-4 w-full bg-surface px-4 pb-[max(12px,calc(2*env(safe-area-inset-bottom)))] pt-3',
          { sticky, 'border-t': shadow },
        )}
        {...props}
      >
        {children}
      </div>
    </>
  )
}

export const CartLayoutHeader = ({
  sticky = true,
  children,
}: PropsWithChildren<{ sticky?: boolean }>) => {
  return (
    <header className={cn('top-14 z-[2] overflow-hidden pb-4', { sticky })}>
      <div className="flex h-14 items-center justify-between border-b bg-surface-ui-background px-4 py-3">
        {children}
      </div>
    </header>
  )
}

export const CartLayoutTitle = ({ children }: PropsWithChildren<{}>) => {
  return <div className="flex-1 truncate my-label-md">{children}</div>
}

export const CartNoticeCTA = ({
  children,
  ...buttonProps
}: PropsWithChildren<{
  className?: string
  onClick?: any
}>) => (
  <Button
    type="button"
    variant="destructive"
    size="sm"
    className="flex-shrink-0"
    {...buttonProps}
  >
    {children}
  </Button>
)

export const CartNoticeLink = ({
  children,
  ...props
}: PropsWithChildren<{ onClick?(): void }>) => (
  <a className="text-inherit underline" {...props}>
    {children}
  </a>
)

export const CartErrorNotice = ({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) => (
  <div
    className={cn(
      className,
      '-mt-4 mb-4 bg-semantic-critical-surface-subtle py-2 pl-4 pr-1 text-semantic-critical-foreground-subtle',
    )}
  >
    <div className="flex items-center justify-between my-body-sm">
      {children}
    </div>
  </div>
)

export const CartErrorNoticeWithLinkToMenu = ({
  venueSlug,
  orderingTypeSlug,
  children,
}: PropsWithChildren<{
  venueSlug: string
  orderingTypeSlug?: OrderingTypeSlug
}>) => (
  <CartErrorNotice>
    <div>{children}</div>

    {orderingTypeSlug && (
      <RouteLink
        route={routes.menuCategories}
        pathData={{ venueSlug, orderingTypeSlug }}
      >
        <CartNoticeLink>
          <CartNoticeCTA>
            <FormattedMessage defaultMessage="Menu" id="tKMlOc" />
          </CartNoticeCTA>
        </CartNoticeLink>
      </RouteLink>
    )}
  </CartErrorNotice>
)

export const CartErrorNoticeWithLinkToVenueLanding = ({
  venueSlug,
  children,
}: PropsWithChildren<{
  venueSlug: string
}>) => (
  <CartErrorNotice>
    <div>{children}</div>
    <RouteLink route={routes.venue} pathData={{ venueSlug }}>
      <CartNoticeLink>
        <CartNoticeCTA>
          <FormattedMessage defaultMessage="Go back" id="orvpWh" />
        </CartNoticeCTA>
      </CartNoticeLink>
    </RouteLink>
  </CartErrorNotice>
)

export const CartErrorNoticeWithChangeOrderingType = ({
  venueSlug,
  orderingTypeSlug,
  children,
}: PropsWithChildren<{
  venueSlug: string
  orderingTypeSlug: OrderingTypeSlug
}>) => (
  <CartErrorNotice>
    <div>{children}</div>
    <RouteLink
      route={routes.menuCategories}
      pathData={{
        orderingTypeSlug,
        venueSlug,
        sheet: 'ordering-type',
      }}
    >
      <CartNoticeLink>
        <CartNoticeCTA>
          <FormattedMessage defaultMessage="Go back" id="orvpWh" />
        </CartNoticeCTA>
      </CartNoticeLink>
    </RouteLink>
  </CartErrorNotice>
)

export const CartNeutralNotice = ({ children }: PropsWithChildren<{}>) => (
  <div className="-mt-4 mb-4 bg-surface-bright pl-4 text-foreground">
    <p className="flex items-center justify-between my-body-sm">{children}</p>
  </div>
)

export const CartStaleWrapper = ({
  children,
  loading = false,
  spinner = true,
  className,
}: PropsWithChildren<{
  className?: string
  loading?: boolean
  spinner?: boolean
}>) => {
  return (
    <div className={cn('relative', className)}>
      {children}

      {loading && (
        <div className="absolute inset-0 flex items-center justify-between bg-surface-ui-background opacity-75">
          {spinner && (
            <div className="absolute left-1/2 top-1/2 h-8 w-8 -translate-x-1/2 -translate-y-1/2">
              <Spinner className="text-interactive-accent-foreground-active" />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
